import { useTranslation } from "react-i18next";

export default function MenuItems(props) {
  const { t } = useTranslation();

  return (
    <li>
      <a
        className={props.currentPage}
        href={props.pageLink}
      >
        {t(props.pageHome)}
      </a>
    </li>
  );
}
