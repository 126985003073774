import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import FullBlogPost from "../components/blog/fullBlogPost";

export default function FullBlogPage(props) {
  return (
    <>
      <Menu></Menu>
      <FullBlogPost postTitle={props.postTitle} />
      <Footer></Footer>
    </>
  );
}
