import React from "react";
import { useTranslation } from "react-i18next";
import "./hero.css";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div className="heroContainer">
      <h1 className="hero-content">{t("Welcome to Sites for Everyone")}</h1>
      <p className="hero-content">
        {t("We provide innovative solutions for your business needs")}
      </p>
      <a href="/contact">
        <button className="button hero-content">{t("Get Started")}</button>
      </a>
    </div>
  );
}
