import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import "./ContactForm.css"; // Import your CSS file
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [messageWorked, setMessageWorked] = useState(false);
  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage(t("Message sent!"));
          setIsSubmitting(false);

          setTimeout(() => {
            setStateMessage(null);
            setMessageWorked(true);
          }, 1000); // hide message after 1 second
        },
        (error) => {
          setStateMessage(t("Something went wrong, please try again later"));
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <div className="form-container">
      <h1>{t("Contact Us")}</h1>
      <form
        onSubmit={sendEmail}
        className="contact-form"
      >
        <label htmlFor="name">{t("Name")}</label>
        <input
          id="name"
          type="text"
          name="name"
          className="form-input"
          placeholder={t("Your Name")}
          required
        />
        <label htmlFor="email">{t("Email")}</label>
        <input
          id="email"
          type="email"
          name="email"
          className="form-input"
          placeholder={t("Your Email")}
          required
        />
        <label htmlFor="message">{t("Message")}</label>
        <textarea
          id="message"
          name="message"
          className="form-textarea"
          placeholder={t("Your Message")}
          required
        />
        <button
          type="submit"
          className="form-submit"
          disabled={isSubmitting}
        >
          {t(isSubmitting ? "Sending..." : "Send")}
          {messageWorked ? (
            <Navigate
              to="../thankyou"
              replace={true}
            />
          ) : null}
        </button>
        {stateMessage && <p className="form-message">{t(stateMessage)}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
