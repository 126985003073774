import { useEffect, useState } from "react";
import Menu from "../menu/menu";
import Footer from "../footer/footer";
import "./createPost.css";
import axios from "axios";

export default function CretePost() {
  const [writerName, setWriterName] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postContent, setPostContent] = useState("");
  const [postPreivew, setPostPreivew] = useState("");
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    setTimeout(hideToast, 1500);
  });
  const hideToast = () => {
    setIsShown(false);
  };
  function sendPostData(event) {
    axios
      .post("/new", {
        writersName: writerName,
        postTitle: postTitle,
        content: postContent,
        preview: postPreivew,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    event.preventDefault();
  }
  const handleNameChange = (event) => {
    setWriterName(event.target.value);
  };
  const handleTitleChange = (event) => {
    setPostTitle(event.target.value);
  };
  const handleContentChange = (event) => {
    setPostContent(event.target.value);
  };
  const handlePrewChange = (event) => {
    setPostPreivew(event.target.value);
  };
  const onClickHandlerSubmit = () => {
    setIsShown(true);
  };
  return (
    <div>
      <Menu></Menu>
      <form
        onSubmit={sendPostData}
        className="formCreate"
      >
        <label htmlFor="pTitle"> Post Title</label>
        <input
          type="text"
          id="pTitle"
          name="pTitle"
          onChange={handleTitleChange}
          value={postTitle}
        ></input>
        <label htmlFor="pWriter"> Post Writer</label>
        <input
          type="text"
          id="pWriter"
          name="pWriter"
          onChange={handleNameChange}
          value={writerName}
        ></input>
        <label htmlFor="pContent"> Post Content</label>
        <textarea
          type="text"
          id="pContent"
          name="pContent"
          onChange={handleContentChange}
          value={postContent}
        ></textarea>
        <label htmlFor="pPrev"> Post Preivew</label>
        <textarea
          type="text"
          id="pPrev"
          name="pPrev"
          onChange={handlePrewChange}
          value={postPreivew}
        ></textarea>
        <label htmlFor="imgPath">Image Path</label>
        <input
          type="text"
          id="imgPath"
          name="imgPath"
        ></input>
        {/* Temp way to add an image, just a link from online */}
        {/* <input
          type="file"
          accept="image/*"
        ></input> */}
        {/* TODO add a photo */}
        <input
          type="submit"
          value="Submit"
          onClick={onClickHandlerSubmit}
        ></input>
        <label id="toastLabel" style={{ visibility: isShown ? "visible" : "hidden" }}>
          Post Sent
        </label>

      </form>
      <Footer></Footer>
    </div>
  );
}
