import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";
import israeliFlag from "../../img/israeli_flag_icon.jpg";
import usaFlag from "../../img/usa_flag_icon.jpg";
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      <button
        onClick={() => changeLanguage("en")}
        className="language-button"
      >
        <img
          src={usaFlag}
          alt="English"
          className="flag-icon"
        />
      </button>
      <button
        onClick={() => changeLanguage("he")}
        className="language-button"
      >
        <img
          src={israeliFlag}
          alt="Hebrew"
          className="flag-icon"
        />
      </button>
    </div>
  );
};
export default LanguageSwitcher;
