import React from "react";
import { useTranslation } from "react-i18next";
import "./aboutSomething.css";

export default function AboutSomething(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="container main aboutText">
        <h3 className={props.classnameHeader || ""}>{t(props.subHeader)}</h3>
        <p className={props.classname || ""}>
          {t(props.paragraphText)} <a href={props.link}>{t(props.linkText)}</a>
        </p>
        
      </div>
    </>
  );
}
