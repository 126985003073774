import React from "react";
import { useTranslation } from "react-i18next";
import "./footer.css";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <p>
        &copy; {currentYear} {t("Sites for Everyone. All rights reserved.")}
      </p>
    </footer>
  );
}
