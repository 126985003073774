import React from "react";
import { useTranslation } from "react-i18next";
import "./ThankYou.css";

const ThankYou = () => {
  const { t } = useTranslation();

  return (
    <div className="bodyThanks">
      <div className="containerThak">
        <h1 className="h1Thak">{t("Thank You!")}</h1>
        <p className="pthank">
          {t(
            "We appreciate you taking the time to provide your details. Our team will be in touch with you shortly."
          )}
        </p>
        <a
          href="./contact"
          className="btnthank"
        >
          {t("Back to Home")}
        </a>
      </div>
    </div>
  );
};

export default ThankYou;
