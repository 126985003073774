import React from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import MenuBar from "../components/menu/menu";
import Hero from "../components/hero/hero";
import FearturedHome from "../components/Featured/feartured";
import AboutSomething from "../components/aboutSometing/aboutSomething";
import Footer from "../components/footer/footer";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <div className="App-header">
        <MenuBar currentPage="home" />
        <Hero />
      </div>
      <FearturedHome counter={3} />
      <h2 className="headerH1">{t("ourServices")}</h2>
      <div className="grid">
        <AboutSomething
          subHeader={t("graphicDesign")}
          paragraphText={t("graphicDesignTextHome")}
          link="/service"
          linkText={t("learnMore")}
          classname="ser"
          classnameHeader="serHeader"
        />
        <AboutSomething
          subHeader={t("webDevelopment")}
          paragraphText={t("webDevelopmentTextHome")}
          link="/service"
          linkText={t("learnMore")}
          classname="ser"
          classnameHeader="serHeader"
        />
        <AboutSomething
          subHeader={t("seoWriting")}
          paragraphText={t("seoWritingTextHome")}
          link="/service"
          linkText={t("learnMore")}
          classname="ser"
          classnameHeader="serHeader"
        />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
