import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "../components/menu/menu";
import AboutSomething from "../components/aboutSometing/aboutSomething";
// import myHeeadShot from "../img/Schwartzberg_Moshe_271-0567-Retouched.jpg";
import myHeeadShot from "../img/Schwartzberg_Moshe_271-0567-Retouched.png";
import Footer from "../components/footer/footer";
import LanguageSwitcher from "../components/langSwitch/LanguageSwitcher";
import "./about.css";
import "../components/aboutSometing/aboutSomething.css"
export default function About() {
  const { t } = useTranslation();

  return (
    <>
      <Menu currentPage="about" />
      {/* <LanguageSwitcher /> */}
      <div className="grid section">
        <AboutSomething
          subHeader={t("aboutMe")}
          paragraphText={t("aboutMeText")}
        />
        <img
          src={myHeeadShot}
          className="headShotAbout"
          alt={t("aboutMe")}
        />
      </div>
      <Footer />
    </>
  );
}
