import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      aboutMe: "Hello, I am Moshe Schwartzberg",
      aboutMeText:
        "I am Moshe Schwartzberg, a versatile former IDF team leader and full-stack programmer with a robust background in Electrical Engineering. I specialize in backend development and Java, excelling in driving efficient and scalable solutions as the Full Stack & Customer Relations Manager at Sites for Everyone. My expertise spans React, TypeScript, JavaScript, and a wide array of technologies, including AWS, SQL, and various frontend and backend frameworks. I pride myself on my exceptional ability to work with customers, ensuring their needs are met and their visions are realized. This commitment to excellence allows me to create some of the best websites tailored to clients' unique requirements. I am passionate about leveraging my skills to contribute to growth and advance into leadership positions.",
      ourServices: "Our Services",
      graphicDesign: "Graphic Design",
      graphicDesignText:
        "I work with a team of experienced graphic designers who can bring your vision to life with stunning visual designs. From logos and branding to marketing materials and social media graphics, we can help you effectively communicate your message to your target audience.",
      webDevelopment: "Web Development",
      webDevelopmentText:
        "Need a new website or want to update your existing one? Our web developers can help you create a professional, user-friendly website that meets your specific business needs. From custom WordPress themes to e-commerce solutions, we can build a website that helps you succeed online.",
      seoWriting: "SEO Writing",
      seoWritingText:
        "Improve your search engine rankings and drive more traffic to your website with our SEO writing services. My team of expert writers can create high-quality, keyword-rich content that helps your website rank higher in search results and attract your target audience.",
      learnMore: "Learn More",
      "Contact Us": "Contact Us",
      Name: "Name",
      Email: "Email",
      Message: "Message",
      "Your Name": "Your Name",
      "Your Email": "Your Email",
      "Your Message": "Your Message",
      "Sending...": "Sending...",
      Send: "Send",
      "Message sent!": "Message sent!",
      "Something went wrong, please try again later":
        "Something went wrong, please try again later",
      "Welcome to Sites for Everyone": "Welcome to Sites for Everyone",
      "We provide innovative solutions for your business needs":
        "We provide innovative solutions for your business needs",
      "Get Started": "Get Started",
      "Sites for Everyone. All rights reserved.":
        "Sites for Everyone. All rights reserved.",
      Contact: "Contact",
      Blog: "Blog",
      Portfolio: "Portfolio",
      Services: "Services",
      About: "About",
      Home: "Home",
      graphicDesignTextHome:
        "I work with a team of experienced graphic designers who can bring your vision to life with stunning visual designs.",
      webDevelopmentTextHome:
        "Need a new website or want to update your existing one?",
      seoWritingTextHome:
        "Improve your search engine rankings and drive more traffic to your website with our SEO writing services.",
      "Featured Projects": "Featured Projects",
      titleShalomCarmel: "Shalom Carmel",
      titleSmokinTs: "Smokin Ts",
      titleToraDojoIsrael: "Tora Dojo Israel",
      titleDanielYehuda: "Daniel Yehuda",
      titleEnterplus: "Enterplus",
      titleAiPro: "AiPro",
      titleLionsForZion: "Lions For Zion",
      titleOmnifood: "Omnifood",
      titleIDFTene: "IDF Tene",
      titleIDFTene2: "IDF Tene 2",
      titleMaimChaim: "Maim Chaim",
      "Thank You!": "Thank You!",
      "We appreciate you taking the time to provide your details. Our team will be in touch with you shortly.":
        "We appreciate you taking the time to provide your details. Our team will be in touch with you shortly.",
      "Back to Home": "Back to Home",
      titleIsraelMahogony: "Israel Mahogony",
      "Read More": "Read More",
      Back: "Back",
      "Written by": "Written by",
      "Posted On": "Posted On",
      "The customer has taken down this project, so the website doesn't exsist anymore":
        "The customer has taken down this project, so the website doesn't exsist anymore",
    },
  },
  he: {
    translation: {
      aboutMe: "שלום, אני משה שוורצברג",
      aboutMeText:
        'אני משה שוורצברג, מנהיג צוות לשעבר בצה"ל ומתכנת פול-סטאק בעל רקע חזק בהנדסת חשמל. אני מתמחה בפיתוח צד שרת וב-Java, ומצטיין במתן פתרונות יעילים ומדרגיים כמנהל פול סטאק ויחסי לקוחות ב-Sites for Everyone. הידע שלי כולל React, TypeScript, JavaScript ומגוון רחב של טכנולוגיות, כולל AWS, SQL ומסגרות צד שרת ולקוח שונות. אני גאה ביכולת המצוינת שלי לעבוד עם לקוחות, לוודא שצרכיהם מתקיימים וחזונם מתממש. המחויבות שלי למצוינות מאפשרת לי ליצור אתרים מהטובים ביותר המותאמים לצרכים הייחודיים של הלקוחות. אני נלהב להשתמש בכישורים שלי לתרום לצמיחה ולהתקדם לתפקידים מנהיגותיים.',
      ourServices: "השירותים שלנו",
      graphicDesign: "עיצוב גרפי",
      graphicDesignText:
        "אני עובד עם צוות של מעצבים גרפיים מנוסים שיכולים להביא את החזון שלך לחיים עם עיצובים ויזואליים מדהימים. מהלוגואים ומיתוג ועד חומרים שיווקיים וגרפיקה למדיה חברתית, אנו יכולים לעזור לך להעביר את המסר שלך לקהל היעד בצורה אפקטיבית.",
      webDevelopment: "פיתוח אתרים",
      webDevelopmentText:
        "צריך אתר חדש או רוצה לעדכן את הקיים? המפתחים שלנו יכולים לעזור לך ליצור אתר מקצועי וידידותי למשתמש שעונה על הצרכים העסקיים הספציפיים שלך. מאתרי וורדפרס מותאמים אישית ועד פתרונות מסחר אלקטרוני, אנו יכולים לבנות אתר שיסייע לך להצליח באינטרנט.",
      seoWriting: "כתיבת SEO",
      seoWritingText:
        "שפר את דירוג האתר שלך במנועי החיפוש והבא יותר תנועה לאתר שלך עם שירותי ה-SEO שלנו. צוות הכותבים המומחים שלנו יכול ליצור תוכן איכותי ועשיר במילות מפתח שיעזור לאתר שלך לדרג גבוה יותר בתוצאות החיפוש ולמשוך את קהל היעד שלך.",
      learnMore: "למד עוד",
      graphicDesignTextHome:
        "אני עובד עם צוות של מעצבים גרפיים מנוסים שיכולים להביא את החזון שלך לחיים עם עיצובים ויזואליים מדהימים. ",

      webDevelopmentTextHome:
        "צריך אתר חדש או רוצה לעדכן את הקיים? המפתחים שלנו יכולים לעזור לך ליצור אתר מקצועי ",
      seoWritingTextHome:
        "שפר את דירוג האתר שלך במנועי החיפוש והבא יותר תנועה לאתר שלך עם שירותי ה-SEO שלנו. ",
      "Contact Us": "צור קשר",
      Name: "שם",
      Email: "אימייל",
      Message: "הודעה",
      "Your Name": "השם שלך",
      "Your Email": "האימייל שלך",
      "Your Message": "ההודעה שלך",
      "Sending...": "שולח...",
      Send: "שלח",
      "Message sent!": "הודעה נשלחה!",
      "Something went wrong, please try again later":
        "משהו השתבש, נסה שוב מאוחר יותר",
      "Welcome to Sites for Everyone": "ברוכים הבאים לSites for Everyone",
      "We provide innovative solutions for your business needs":
        "אנו מספקים פתרונות חדשניים לצרכי העסק שלך",
      "Get Started": "השאירו פרטים",
      "Sites for Everyone. All rights reserved.": "כל הזכויות שמורות",
      Contact: "צור קשר",
      Blog: "בלוג",
      Portfolio: "תיק עבודות",
      Services: "שירותים",
      About: "אודות",
      Home: "בית",
      titleShalomCarmel: "שלום כרמל",
      titleSmokinTs: "סמוקין טיס",
      titleToraDojoIsrael: "תורה דוג'ו ישראל",
      titleDanielYehuda: "דניאל יהודה",
      titleEnterplus: "אנטרפלוס",
      titleAiPro: "איי פרו",
      titleLionsForZion: "אריות לציון",
      titleOmnifood: "אומניפוד",
      titleIDFTene: ' חייל טנא צה"ל ',
      titleIDFTene2: 'חייל טנא צה"ל  2',
      titleMaimChaim: "מים חיים",
      titleIsraelMahogony: "מהוגוני ישראל",
      "Featured Projects": "פרויקטים מובילים",
      "Thank You!": "תודה לך!",
      "We appreciate you taking the time to provide your details. Our team will be in touch with you shortly.":
        "אנו מעריכים את הזמן שהקדשת למסירת הפרטים שלך. הצוות שלנו יצור עמך קשר בקרוב.",
      "Back to Home": "חזרה לדף הבית",
      "Read More": "קרע עוד",
      Back: "חזור",
      "Written by": "נכתב על ידי",
      "Posted On": "פורסם ב",
      "The customer has taken down this project, so the website doesn't exsist anymore":
        "האתר כבר לא קיים מצד הלקוח",
    },
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "he",
    lng: "he",
    debug: true,
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
