import React, { useState } from "react";
import "./menu.css";
import MenuItem from "./menuItems"; // Ensure the path is correct
import LanguageSwitcher from "../langSwitch/LanguageSwitcher"; // Adjust path as necessary

export default function Menu(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <div className="container menuHeader">
        <div className="branding">
          <h1>
            <span className="highlight">Sites </span>
            for Everyone
          </h1>
        </div>
        <button
          className="menu-toggle"
          onClick={toggleMenu}
        >
          ☰
        </button>
        <nav className={isOpen ? "open" : ""}>
          <ul>
          <LanguageSwitcher />
            <MenuItem
              pageLink="/"
              pageHome="Home"
              currentPage={props.currentPage === "home" ? "currentPage" : ""}
            />
            <MenuItem
              pageLink="/about"
              pageHome="About"
              currentPage={props.currentPage === "about" ? "currentPage" : ""}
            />
            <MenuItem
              pageLink="/service"
              pageHome="Services"
              currentPage={props.currentPage === "service" ? "currentPage" : ""}
            />
            <MenuItem
              pageLink="/portfolio"
              pageHome="Portfolio"
              currentPage={
                props.currentPage === "portfolio" ? "currentPage" : ""
              }
            />
            <MenuItem
              pageLink="/blog"
              pageHome="Blog"
              currentPage={props.currentPage === "blog" ? "currentPage" : ""}
            />
            <MenuItem
              pageLink="/contact"
              pageHome="Contact"
              currentPage={props.currentPage === "contact" ? "currentPage" : ""}
            />
          </ul>
        </nav>
      </div>
    </header>
  );
}
