import Menu from "../components/menu/menu";
import ContactForm from "../components/contact/contactForm";
import Footer from "../components/footer/footer";
export default function contact() {
  return (
    <>
      <Menu currentPage="contact" />
      <ContactForm></ContactForm>
      <Footer></Footer>
    </>
  );
}
