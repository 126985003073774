import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import Feartured from "../components/Featured/feartured";
export default function portfolio() {
  return (
    <>
      <Menu currentPage="portfolio"></Menu>
      <div>
        <Feartured
          counter="20"
          randomOrNot={false}
        ></Feartured>
      </div>
      <Footer></Footer>
    </>
  );
}
