import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import Portfolio from "./pages/portfolio";
import Service from "./pages/service";
import ThankYouPage from "./components/thankyou/thankyou";
import CretePost from "./components/blog/createPost";
import FullBlogPostPage from "./pages/fullBlogPostPage";
import DeadProject from "./pages/deadProject";
function App() {
  
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleLanguageChange = () => {
      // Set the 'dir' attribute according to the current language
      document.documentElement.dir = i18n.language === "he" ? "rtl" : "ltr";
    };

    handleLanguageChange(); // Call it on component mount or update

    // Subscribe to language changes
    i18n.on("languageChanged", handleLanguageChange);

    // Cleanup the effect by unsubscribing to the event
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]); // Dependency on the i18n object

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/about"
          element={<About />}
        />
        <Route
          path="/blog"
          element={<Blog />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />
        <Route
          path="/portfolio"
          element={<Portfolio />}
        />
        <Route
          path="/service"
          element={<Service />}
        />
        <Route
          path="/thankyou"
          element={<ThankYouPage />}
        />
        <Route
          path="/blog/new"
          element={<CretePost />}
        />
        <Route
          path="/full"
          element={<FullBlogPostPage />}
        />
        <Route
          path="/deadProj"
          element={<DeadProject/>}
        />
      </Routes>
    </Router>
  );
}

export default App;
