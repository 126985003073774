import React from "react";
import ProjectFeat from "./ProjectFeat";
import { useTranslation } from "react-i18next";

import shalomCarmelImg from "../../img/portfolio/ShalomCarmel.jpg";
import smokinTsImg from "../../img/portfolio/SmokinT's.jpg";
import toraDojoIsraelImg from "../../img/portfolio/ToraDojoIsrael.jpg";
import danielYehuda from "../../img/portfolio/DanielYehuda.jpg";
import enterplus from "../../img/portfolio/EnterPlus.png";
import AiPro from "../../img/portfolio/ItaiArenFeld.png";
import LionsForZion from "../../img/portfolio/LionsforZion.jpg";
import omnifood from "../../img/portfolio/Omnifood-neverCookgain.png";
import idftene from "../../img/portfolio/idftene.png";
import idftene2 from "../../img/portfolio/idftene2.0.png";
import maim from "../../img/portfolio/maim-chaim.jpg";
import mahogonyIsrael from "../../img/portfolio/MahogonyIsrael.png";
import "./feartured.css";
export default function Featured(props) {
  const { t } = useTranslation();
  const imgTitles = [
    t("titleShalomCarmel"),
    t("titleSmokinTs"),
    t("titleToraDojoIsrael"),
    t("titleDanielYehuda"),
    t("titleEnterplus"),
    t("titleAiPro"),
    t("titleLionsForZion"),
    t("titleOmnifood"),
    t("titleIDFTene"),
    t("titleIDFTene2"),
    t("titleMaimChaim"),
    t("titleIsraelMahogony"),
  ];
  const imgArr = [
    shalomCarmelImg,
    smokinTsImg,
    toraDojoIsraelImg,
    danielYehuda,
    enterplus,
    AiPro,
    LionsForZion,
    omnifood,
    idftene,
    idftene2,
    maim,
    mahogonyIsrael,
  ];
  const linkArr = [
    "https://shalom1.ussl.co",
    "https://smokin-t.com/",
    "https://toradojo.co.il",
    "https://danielyehuda.co.il",
    "https://enterplusco.com/",
    "https://ai-pro.co.il/",
    "/deadProj",
    "http://Omnifood.dev",
    "/deadProj",
    "/deadProj",
    "http://Maimchaim.org.il",
    "/deadProj",
  ];
  const startIdx = Math.floor(Math.random() * imgArr.length);
  let mappedImages = [];
  let randomOrNot = props.randomOrNot ?? true;

  for (let count = 0; count < props.counter && count < imgArr.length; count++) {
    const currentIndex = randomOrNot
      ? (startIdx + count) % imgArr.length
      : count;
    mappedImages.push(
      <ProjectFeat
        key={currentIndex}
        featHeader={imgTitles[currentIndex]}
        featImg={imgArr[currentIndex]}
        altText={imgTitles[currentIndex]}
        link={linkArr[currentIndex]}
      />
    );
  }

  return (
    <div className="featContainer">
      <h2>{t("Featured Projects")}</h2>
      <div className="featImg">{mappedImages}</div>
    </div>
  );
}
