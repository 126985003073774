import "./projectFeat.css";

export default function ProjectFeat(props) {
  return (
    <div>
      <a href={props.link} target="_black">
        <img
          className="imgPorj"
          src={props.featImg}
          alt={props.altText}
        ></img>
      </a>
      <h3 className="featHeader">{props.featHeader}</h3>
    </div>
  );
}
