import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import AboutSomething from "../components/aboutSometing/aboutSomething";

export default function Service() {
  const { t } = useTranslation();

  return (
    <>
      <Menu currentPage="service" />
      <h2 className="headerH1">{t("ourServices")}</h2>
      <div className="grid">
        <AboutSomething
          subHeader={t("graphicDesign")}
          paragraphText={t("graphicDesignText")}
          classname="ser"
          classnameHeader="serHeader"
        />
        <AboutSomething
          subHeader={t("webDevelopment")}
          paragraphText={t("webDevelopmentText")}
          classname="ser"
          classnameHeader="serHeader"
        />
        <AboutSomething
          subHeader={t("seoWriting")}
          paragraphText={t("seoWritingText")}
          classname="ser"
          classnameHeader="serHeader"
        />
      </div>
      <Footer />
    </>
  );
}
