import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function DeadProject() {
  const { t } = useTranslation();

  return (
    <>
      <Menu></Menu>
      <h1>
        {t(
          "The customer has taken down this project, so the website doesn't exsist anymore"
        )}
      </h1>
      <Footer></Footer>
    </>
  );
}
